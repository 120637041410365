/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { fromJS, List as ImmutableList } from 'immutable';
import { NavigateFunction, useLocation, useNavigate, Location } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  createStyles,
  StyledComponentProps,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
// Components
import { RouteBackground } from 'components';
import { MemberPrimaryInfoStep, Stepper } from 'common/components';
import { StepContext } from 'common/createContext/stepContext';
import { LeadPrimaryInfoStep } from 'modules/crm/components';
import Footer from 'modules/crm/containers/NewLead/Footer';
import TrialMembershipStepContainer from 'common/containers/StepContainers/TrialMembershipStepContainer/TrialMembershipStepContainer';
import SignWaiversStepContainer from 'common/containers/StepContainers/SignWaiversStepContainer/SignWaiversStepContainer';
import {
  AdditionalInfoStepContainer,
  FamilyMembersStepContainer,
  PaymentStepContainer,
  ScheduleTourStepContainer,
  SignContractStepContainer,
} from 'common/containers';
// Messages
import leadsMessages from 'modules/crm/messages/leads';
// Constants
import {
  ChangeMembershipStepsLabels,
  NewLeadSteps,
  NewLeadStepsLabels,
} from 'modules/crm/constants/leads';
import { ActionResult, DictionaryList, QueryPageList } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
// interfaces
import { INewLeadProfileData } from 'modules/crm/interfaces/leads';
import {
  ICameFromIndicatorDictionaryItemImt,
  IEmployeeListItemImt,
} from 'common/interfaces/dictionary';
import {
  IImmutablePrimaryMemberInfo,
  IProfileInfoImt,
} from 'common/components/PersonProfile/interfaces';
import { IMembershipPackageShort } from 'common/interfaces/membership';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
// hooks
import { useAppDispatch } from 'store/hooks';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { createLeadProfile, fetchLeadActivities } from 'modules/crm/state/leads/actions';
// Selectors
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import { selectLeadUpdateLoading } from 'modules/crm/state/leads/selectors';
import { createMember } from 'common/state/newPerson/primaryInfo/actions';
import { selectActionCreateResult } from 'common/state/newPerson/primaryInfo/selectors';

interface INewLeadHOCProps {
  hasChangePackagePlanMode?: boolean;
  profile?: IProfileInfoImt;
  onCloseModal?: () => void;
  mainTitle?: JSX.Element | string;
}

interface INewLeadProps extends StyledComponentProps, INewLeadHOCProps {
  employees: ImmutableList<IEmployeeListItemImt>;
  cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt>;
  queryLeads?: string;
  selectedClubId?: string;
  createLeadLoading: boolean;
  fetchEmployees: () => void;
  fetchCameFromIndicators: () => void;
  fetchActivities: () => void;
  saveLeadProfile: (clubId: string, data: INewLeadProfileData) => void;
  navigate: NavigateFunction;
  location: Location;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm | PeakModules.Members;
  isResponseSuccess: boolean;
}

interface INewLeadState extends INewLeadProfileData {
  currentStep: NewLeadSteps;
  selectedPackage: IMembershipPackageShort;
  clubId?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxWidth: ({ hasChangePackagePlanMode }: INewLeadProps) =>
        hasChangePackagePlanMode ? '100%' : '630px',
    },
    pageTitle: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    stepper: {
      padding: theme.spacing(2),
    },
  });

const { Provider: StepProvider } = StepContext;

const NewLeadHOC = (props: INewLeadHOCProps): JSX.Element => {
  const { hasChangePackagePlanMode, profile, onCloseModal, mainTitle } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const employees: ImmutableList<IEmployeeListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );
  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );
  const selectedClubId: string = useSelector(selectUserSelectedClubId);
  const queryLeads: string = useSelector(selectQueryPageList(QueryPageList.LEADS));
  const createLeadLoading: boolean = useSelector(selectLeadUpdateLoading);
  const actionResult = useSelector(selectActionCreateResult);

  let module: PeakModules.Crm | PeakModules.PersonalTrainingCrm | PeakModules.Members;

  if (location.pathname.indexOf('personal-training-crm') !== -1) {
    module = PeakModules.PersonalTrainingCrm;
  } else if (location.pathname.indexOf('crm') !== -1) {
    module = PeakModules.Crm;
  } else {
    module = PeakModules.Members;
  }

  const fetchEmployees = () => dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
  const fetchCameFromIndicators = () =>
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));
  const saveLeadProfile = (clubId, data) =>
    dispatch(
      module === PeakModules.Members
        ? createMember({ ...data, initialClubId: data.clubId }, module)
        : createLeadProfile(clubId, data, module),
    );
  const fetchActivities = () => dispatch(fetchLeadActivities(module));

  const newLeadProps = {
    employees,
    selectedClubId,
    createLeadLoading,
    hasChangePackagePlanMode,
    cameFromIndicators,
    navigate,
    location,
    profile,
    queryLeads,
    fetchEmployees,
    fetchActivities,
    saveLeadProfile,
    onCloseModal,
    fetchCameFromIndicators,
    mainTitle,
    module,
    isResponseSuccess:
      module === PeakModules.Members && actionResult === ActionResult.SUCCESS_ACTION,
  };
  return <NewLead {...newLeadProps} />;
};

@(withStyles(styles) as any)
class NewLead extends Component<INewLeadProps, INewLeadState> {
  private steps: NewLeadSteps[] = [
    NewLeadSteps.PrimaryInfo,
    NewLeadSteps.TrialMembership,
    NewLeadSteps.AdditionalInfo,
    NewLeadSteps.Payment,
    NewLeadSteps.FamilyMembers,
    NewLeadSteps.SignWaivers,
    NewLeadSteps.SignContract,
    NewLeadSteps.ScheduleTour,
  ];

  constructor(props: INewLeadProps) {
    super(props);
    const { hasChangePackagePlanMode, selectedClubId } = this.props;

    this.state = {
      currentStep: hasChangePackagePlanMode
        ? NewLeadSteps.TrialMembership
        : NewLeadSteps.PrimaryInfo,
      selectedPackage: fromJS({}),
      clubId: selectedClubId,
    };

    if (hasChangePackagePlanMode) {
      // delete the first step
      this.steps.shift();
    }
  }

  private get currentStepIndex(): number {
    const { currentStep } = this.state;
    return this.steps.indexOf(currentStep);
  }

  private get nextStep(): NewLeadSteps {
    return this.currentStepIndex + 1 < this.steps.length
      ? this.steps[this.currentStepIndex + 1]
      : null;
  }

  private get prevStep(): NewLeadSteps {
    return this.currentStepIndex - 1 < 0 ? null : this.steps[this.currentStepIndex - 1];
  }

  static getDerivedStateFromProps(props: INewLeadProps, state: INewLeadState) {
    const { profile } = props;
    const { id } = state;
    if (profile && !id) {
      return {
        ...profile.toJS(),
      };
    }
    return null;
  }

  componentDidMount(): void {
    const { fetchEmployees, fetchCameFromIndicators, fetchActivities } = this.props;

    fetchEmployees();
    fetchCameFromIndicators();
    fetchActivities();
  }

  private handleOnSubmitStep = (data: Partial<INewLeadProfileData>): void => {
    this.setState({ currentStep: this.nextStep, ...data });
  };

  private saveProfile = (data: Partial<INewLeadProfileData>) => {
    const { employees, selectedClubId, saveLeadProfile } = this.props;
    const { currentStep, ...profileData } = this.state;

    const transformedActivitiesIds = (data.activitiesIds as IDictionaryCommonItem[])?.map(
      activityItem => activityItem.id,
    ) as string[];

    const payload = { ...profileData, ...data, activitiesIds: transformedActivitiesIds };

    const assignedSalespersonId = payload.assignedSalesperson?.id;

    payload.assignedSalesperson = assignedSalespersonId
      ? employees.find(c => c.get('id') === assignedSalespersonId)?.toJS() || null
      : null;

    saveLeadProfile(selectedClubId, payload);
  };

  private handleOnCancelStep = (data?: Partial<INewLeadProfileData>): void => {
    if (this.prevStep === null) {
      this.goBack();
    } else {
      this.setState({ currentStep: this.prevStep, ...data });
    }
  };

  private handleOnExistingPerson = (person: IImmutablePrimaryMemberInfo): void => {
    const { navigate, location, module } = this.props;

    const leadPath = `/${location.pathname.split('/')[1]}/leads/${person.get('id')}`;
    const memberPath = `/members/${person.get('id')}`;

    navigate(module === PeakModules.Members ? memberPath : leadPath);
  };

  private setSelectedPackage = (data: IMembershipPackageShort): void => {
    const { ...profileData } = this.state;
    this.setState({
      ...profileData,
      selectedPackage: data,
    });
  };

  private goBack = () => {
    const { navigate, location, queryLeads, onCloseModal, module } = this.props;
    const { id } = this.state;

    if (onCloseModal) {
      onCloseModal();
    } else {
      navigate(
        module === PeakModules.Members
          ? '/members'
          : `/${location.pathname.split('/')[1]}/leads${id ? `/${id}` : queryLeads}`,
      );
    }
  };

  private renderFooter = (onBack, onNext, isLoading = false): JSX.Element => {
    const { createLeadLoading, hasChangePackagePlanMode } = this.props;
    const { currentStep } = this.state;

    return (
      <Footer
        hasChangePackagePlanMode={hasChangePackagePlanMode}
        goBack={this.goBack}
        onBack={onBack}
        onNext={onNext}
        createLeadLoading={createLeadLoading || isLoading}
        currentStep={currentStep}
      />
    );
  };

  render(): JSX.Element {
    const {
      classes,
      employees,
      cameFromIndicators,
      hasChangePackagePlanMode = false,
      mainTitle,
      module,
      profile,
      isResponseSuccess,
    } = this.props;
    const {
      selectedPackage,
      currentStep,
      waivers,
      membership,
      additionalInfo,
      event,
      // membershipId,
      clubId,
      ...primaryInfo
    } = this.state;

    return (
      <RouteBackground hasNoPadding={hasChangePackagePlanMode} fullScreenView>
        <Card className={classes.root}>
          <Box className={classes.pageTitle}>
            <Typography component="h1" variant="h2">
              {mainTitle || <FormattedMessage {...leadsMessages.newLeadTitle} />}
            </Typography>
          </Box>

          <Stepper
            className={classes.stepper}
            currentStep={this.currentStepIndex}
            steps={this.steps.map(step => ({
              title: hasChangePackagePlanMode
                ? ChangeMembershipStepsLabels[step]
                : NewLeadStepsLabels[step],
            }))}
          />

          <StepProvider
            value={{
              onBack: this.handleOnCancelStep,
              onNext: this.handleOnSubmitStep,
              renderFooter: this.renderFooter,
              done: this.goBack,
              helperData: { profileId: hasChangePackagePlanMode ? profile.get('id') : null },
            }}
          >
            {currentStep === NewLeadSteps.PrimaryInfo &&
              (module === PeakModules.Members ? (
                <MemberPrimaryInfoStep
                  cameFromIndicators={cameFromIndicators}
                  employees={employees}
                  primaryInfo={fromJS(primaryInfo)}
                  setPrimaryInfo={this.handleOnExistingPerson}
                  handleOnSubmit={this.saveProfile}
                  module={module}
                  isResponseSuccess={isResponseSuccess}
                />
              ) : (
                <LeadPrimaryInfoStep
                  module={module}
                  isNewLeadPage
                  employees={employees}
                  cameFromIndicators={cameFromIndicators}
                  primaryInfo={fromJS(primaryInfo)}
                  saveLeadProfile={this.saveProfile}
                  setPrimaryInfo={this.handleOnExistingPerson}
                />
              ))}

            {currentStep === NewLeadSteps.TrialMembership && (
              <TrialMembershipStepContainer
                selectedClubId={clubId}
                module={module}
                setSelectedPackage={this.setSelectedPackage}
              />
            )}

            {currentStep === NewLeadSteps.AdditionalInfo && (
              <AdditionalInfoStepContainer module={module} />
            )}

            {currentStep === NewLeadSteps.Payment && <PaymentStepContainer module={module} />}

            {currentStep === NewLeadSteps.FamilyMembers && (
              <FamilyMembersStepContainer leadPrimaryInfo={fromJS(primaryInfo)} module={module} />
            )}

            {currentStep === NewLeadSteps.SignWaivers && (
              <SignWaiversStepContainer module={module} />
            )}

            {currentStep === NewLeadSteps.SignContract && (
              <SignContractStepContainer module={module} />
            )}

            {currentStep === NewLeadSteps.ScheduleTour && (
              <ScheduleTourStepContainer clubId={clubId} module={module} event={event} />
            )}
          </StepProvider>
        </Card>
      </RouteBackground>
    );
  }
}

export default NewLeadHOC;
