import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Grid, Typography } from '@material-ui/core';

import { fetchDictionaryList } from 'common/state/dictionary/actions';
import {
  resetProfileUpdateActionResult,
  updatePersonAdditionalInfo,
} from 'common/components/PersonProfile/state/actions';
import {
  selectDictionaryList,
  selectIsDictionaryListLoading,
} from 'common/state/dictionary/selectors';
import {
  selectPersonUpdateProfileActionLoading,
  selectPersonUpdateProfileActionResult,
} from 'common/components/PersonProfile/state/selectors';

import { useAppDispatch } from 'store/hooks';

import { INamedEntityImt } from 'common/interfaces/common';
import {
  IAdditionalFieldsImt,
  IProfileAdditionalPayload,
} from 'common/components/PersonProfile/interfaces';

import { PeakModules } from 'common/constants/peakModules';
import { ActionResult, DictionaryList } from 'common/constants';

import { DialogComponent, LoadingBackdrop, TextArea } from 'common/components/index';

import messages from 'common/components/PersonProfile/messages';

interface IFormValues {
  additionalFields: IProfileAdditionalPayload[];
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  profileId: number;
  additionalFields: IAdditionalFieldsImt;
  module: PeakModules;
}

const EditMoreInfo = ({
  isOpen,
  onClose,
  profileId,
  module,
  additionalFields,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const isAdditionalFieldsLoading: boolean = useSelector(
    selectIsDictionaryListLoading(DictionaryList.ADDITIONAL_FIELDS),
  );
  const additionalFieldsList: ImmutableList<INamedEntityImt> = useSelector(
    selectDictionaryList(DictionaryList.ADDITIONAL_FIELDS),
  );
  const isAdditionalProfileUpdating: boolean = useSelector(
    selectPersonUpdateProfileActionLoading(profileId),
  );
  const updateAdditionalProfileActionResult = useSelector(
    selectPersonUpdateProfileActionResult(profileId),
  );

  const formMethods = useForm({
    defaultValues: {
      additionalFields: [{ id: '', value: '' }],
    },
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const { handleSubmit, reset, control } = formMethods;

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.ADDITIONAL_FIELDS, { module }));
  }, [dispatch, module]);

  useEffect(() => {
    if (additionalFieldsList.size) {
      reset({
        additionalFields: Array.from({ length: additionalFieldsList.size }, (_, index) => {
          const fieldId = additionalFieldsList.getIn([index, 'id']);
          const fieldValue = additionalFields
            .find(additionalItem => additionalItem.getIn(['field', 'id']) === fieldId)
            ?.get('value');

          return {
            id: fieldId,
            value: fieldValue || '',
          };
        }),
      });
    }
  }, [additionalFieldsList, additionalFields, reset]);

  useEffect(() => {
    if (updateAdditionalProfileActionResult === ActionResult.SUCCESS_ACTION) {
      onClose();
      dispatch(resetProfileUpdateActionResult(null, profileId));
    }
  }, [dispatch, onClose, profileId, updateAdditionalProfileActionResult]);

  const onSubmit = (values: IFormValues) => {
    dispatch(
      updatePersonAdditionalInfo(
        profileId,
        values.additionalFields.filter(item => Boolean(item.value.trim())),
        module,
      ),
    );
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...messages.editMoreInfoModalTitle} />}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      loading={isAdditionalProfileUpdating}
      size="xs"
      disabled={!additionalFieldsList.size}
    >
      {!!additionalFieldsList.size && (
        <Grid container spacing={1}>
          {additionalFieldsList.map((field: INamedEntityImt, index) => (
            <Grid key={field.get('id')} item xs={12}>
              <Controller
                name={`additionalFields.${index}.value`}
                control={control}
                render={({ field: formField }) => (
                  <TextArea
                    value={formField.value}
                    onChange={formField.onChange}
                    maxSymbols={1000}
                    label={field.get('title')}
                    variant="outlined"
                    fullWidth
                    minRows={8}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {!additionalFieldsList.size && !isAdditionalFieldsLoading && (
        <Typography align="center" variant="h6" component="p" color="textSecondary">
          <FormattedMessage {...messages.noAdditionalFields} />
        </Typography>
      )}

      <LoadingBackdrop isLoading={isAdditionalFieldsLoading} />
    </DialogComponent>
  );
};

export default EditMoreInfo;
