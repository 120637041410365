// libraries
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CheckInBlock } from 'common/components/PersonProfile/components';
// components
import { LoadingBackdrop } from 'common/components';
import ProductItem from './ProductItem';
// interfaces
import {
  CartUnitTypes,
  IInvoiceDetailsDto,
  IInvoiceProductItem,
  InvoiceStatus,
  MenuItemTypes,
} from 'common/interfaces/invoices';
import {
  ISimpleClubInventoryItem,
  ISimpleClubInventoryItemImt,
} from 'modules/pos-settings/interfaces/inventoryItems';
// selectors
import {
  selectPersonSuggestedProducts,
  selectPersonSuggestedProductsLoading,
} from 'common/components/PersonProfile/state/selectors';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import * as selectors from 'modules/authentication/state/selectors';
import { selectCurrentUserId } from 'modules/authentication/state/selectors';
import {
  selectAddInvoiceUnitActionLoading,
  selectIsUpdateInvoiceUnitLoading,
} from 'common/state/invoice/selectors';
// actions
import * as actions from 'common/components/PersonProfile/state/actions';
import * as actionsInvoice from 'common/state/invoice/actions';

import { PeakModules } from 'common/constants/peakModules';

import { usePersonSelectorTemplate } from 'common/components/PersonProfile/hooks/usePersonSelector';
import { useAppDispatch } from 'store/hooks';

// messages
import messages from 'common/components/PersonProfile/messages';
import StorageServices from 'services/storage';
import { CREATE_INVOICE } from 'common/constants/delayedActionsKeys';
import { IUserOrganizationImt } from 'common/interfaces/clients';
import { PermissionLevels } from 'common/constants/permissions';
import useFetchInvoicesList from 'common/httpHooks/useFetchInvoicesList';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: '100%',
    minHeight: '150px',
  },
  padding: {
    padding: theme.spacing(2, 0, 0),
  },
}));

interface IProps {
  personId: number;
  openPOSPanel?: () => void;
  isOpenPOSPanel?: boolean;
}

const { addDelayedAction } = StorageServices.delayedActions;

const SuggestedProducts = ({ personId, openPOSPanel, isOpenPOSPanel }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const [isInvoicesListLoading, setIsInvoicesListLoading] = useState(false);

  const usePersonSelector = usePersonSelectorTemplate(personId);

  const products: ImmutableList<ISimpleClubInventoryItemImt> = usePersonSelector(
    selectPersonSuggestedProducts,
  );
  const isProductsLoading: boolean = usePersonSelector(selectPersonSuggestedProductsLoading);
  const selectedRegisterId: string = useSelector(selectSelectedRegisterId);
  const selectedOrg: IUserOrganizationImt = useSelector(selectors.selectUserSelectedOrganization);
  const currentUserId = useSelector(selectCurrentUserId);
  const isAddInvoiceUnitActionLoading: boolean = useSelector(selectAddInvoiceUnitActionLoading());
  const isUpdateInvoiceUnitLoading = useSelector(selectIsUpdateInvoiceUnitLoading);

  const classes = useStyles();

  const fetchInvoicesList = useFetchInvoicesList({
    module: PeakModules.FrontDesk,
    registerId: selectedRegisterId,
    isMembershipStep: false,
  });

  const fetchPaymentsHistory = useCallback(() => {
    dispatch(actions.fetchRecentPersonPayments(personId, PeakModules.FrontDesk));
  }, [dispatch, personId]);

  useEffect(() => {
    if (personId && selectedOrg.get('permissionsLevel') !== PermissionLevels.CORPORATE) {
      dispatch(actions.fetchPersonSuggestedProducts(personId, selectedOrg.get('id')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, personId]);

  const onSellProduct = useCallback(
    async (product: ISimpleClubInventoryItem): Promise<void> => {
      const handleCreateOrUpdateInvoice = (invoicesList: IInvoiceDetailsDto[]) => {
        const newProduct: IInvoiceProductItem = {
          inventoryId: product.id,
          inventoryClubId: product.id,
          title: product.title,
          imageUrl: product.imageUrl,
          taxAmount: product.taxAmount,
          onHandAmount: product.onHandAmount,
          order: 0,
          number: 1,
          type: MenuItemTypes.Item,
          price: product.retailPrice,
        };

        const openedInvoiceForSelectedCustomer: IInvoiceDetailsDto | void = invoicesList?.find(
          (invoiceItem: IInvoiceDetailsDto) => {
            return (
              invoiceItem.status === InvoiceStatus.OPEN &&
              invoiceItem.customer?.id === personId &&
              invoiceItem.salesperson?.id === currentUserId
            );
          },
        );

        // TODO need to increment open invoices count in register selector DTO(websocket)
        const doAction = (registerId: string) => {
          dispatch(
            actionsInvoice.addInvoiceUnit(
              null,
              {
                createNewInvoice: true,
                inventoryClubs: [newProduct],
                memberId: personId,
                registerId,
              },
              CartUnitTypes.INVENTORY,
              PeakModules.FrontDesk,
              true,
              true,
              fetchPaymentsHistory,
            ),
          );
        };

        if (openedInvoiceForSelectedCustomer) {
          const existedInvoiceUnit = openedInvoiceForSelectedCustomer.invoiceUnits.find(
            invoiceUnit =>
              invoiceUnit.type === CartUnitTypes.INVENTORY &&
              invoiceUnit.inventory.inventorySnapshotSourceId === newProduct.inventoryId,
          );

          if (existedInvoiceUnit) {
            dispatch(
              actionsInvoice.changeInvoiceUnitNumber(
                openedInvoiceForSelectedCustomer.id,
                existedInvoiceUnit.id,
                existedInvoiceUnit.inventory.number + 1,
                PeakModules.FrontDesk,
                false,
                true,
              ),
            );
            openPOSPanel();
          } else {
            dispatch(
              actionsInvoice.addInvoiceUnit(
                openedInvoiceForSelectedCustomer.id,
                {
                  inventoryClubs: [newProduct],
                  memberId: openedInvoiceForSelectedCustomer.customer?.id ?? null,
                },
                CartUnitTypes.INVENTORY,
                PeakModules.FrontDesk,
                true,
                true,
                fetchPaymentsHistory,
              ),
            );
            openPOSPanel();
          }
        } else if (selectedRegisterId && isOpenPOSPanel) {
          doAction(selectedRegisterId);
        } else {
          addDelayedAction(CREATE_INVOICE, doAction);
          openPOSPanel();
        }
      };

      setIsInvoicesListLoading(true);
      await fetchInvoicesList(handleCreateOrUpdateInvoice);
      setIsInvoicesListLoading(false);
    },
    [
      fetchInvoicesList,
      selectedRegisterId,
      isOpenPOSPanel,
      personId,
      currentUserId,
      dispatch,
      fetchPaymentsHistory,
      openPOSPanel,
    ],
  );

  return (
    <CheckInBlock title="SUGGESTED PRODUCTS" className={classes.wrapper}>
      {products?.size ? (
        <Grid container spacing={2} className={classes.padding}>
          {products.map(product => (
            <ProductItem
              key={`suggested-products-${product.get('id')}`}
              product={product}
              onSell={onSellProduct}
              isAddInvoiceUnitActionLoading={
                isAddInvoiceUnitActionLoading || isUpdateInvoiceUnitLoading
              }
            />
          ))}
        </Grid>
      ) : (
        <Box className="empty-section-placeholder">
          <Typography className="empty-text">
            <FormattedMessage {...messages.emptyProductsListMessage} />
          </Typography>
        </Box>
      )}
      <LoadingBackdrop isLoading={isInvoicesListLoading || isProductsLoading} />
    </CheckInBlock>
  );
};

export default SuggestedProducts;
