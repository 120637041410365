import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, GridSize } from '@material-ui/core';
import zipcodes from 'zipcodes';
import get from 'lodash/get';
// components
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import { TextFieldWithReset, ZipCodeMaskedInput } from 'common/components';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useBoxSize from 'common/hooks/useBoxSize';
import { useAppDispatch } from 'store/hooks';
// interfaces
import { IAddress, IStateItemImt } from 'common/interfaces/common';
// constants
import { DictionaryList } from 'common/constants';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
// messages
import inputLabels from 'common/messages/inputLabels';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';
import IntlService from 'services/intl/IntlService';

// TODO - PRM-1810 tmp form interface
interface IFormValues {
  address: IAddress;
  [field: string]: IAddress;
}

interface IAddressBlockProps {
  // if pass - will turn into resettable block
  defaultValues?: IAddress;
  isMultiEdit?: boolean;
  profileMode?: boolean;
  name?: string;
  children?: React.ReactNode;
  recommendedFields?: TShortProfileFieldInfoImt;
}

const AddressBlock = ({
  defaultValues,
  isMultiEdit,
  name = 'address',
  profileMode,
  children,
  recommendedFields,
}: IAddressBlockProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const statesList: ImmutableList<IStateItemImt> = useSelector(
    selectDictionaryList(DictionaryList.STATES),
  );

  const {
    control,
    setValue,
    trigger,
    formState: { isDirty, errors },
  } = useFormContext<IFormValues>();

  const boxRef = useRef<HTMLDivElement | null>(null);
  const { width } = useBoxSize(boxRef);
  const renderIntlMessage = useRenderIntlMessage();
  const isWideBlockWidth = width > 580;
  const isSmallBlockWidth = width < 400;
  const stateOptions = statesList ? statesList.toJS() : [];

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.STATES));
    dispatch(fetchDictionaryList(DictionaryList.COUNTRIES));
  }, [dispatch]);

  const nameCity = `${name}.city`;
  const nameState = `${name}.state`;
  const formErrors = get(errors, name);
  const isDefaultCanResetFlag = isDirty !== undefined && !isDirty;
  const cityFieldSmSize: GridSize = profileMode ? 4 : 6;

  let stateFieldSmSize: GridSize = isSmallBlockWidth ? 6 : 3;
  stateFieldSmSize = profileMode ? 4 : stateFieldSmSize;

  let zipCodeFieldSmSize: GridSize = isSmallBlockWidth ? 6 : 3;
  zipCodeFieldSmSize = profileMode ? 4 : zipCodeFieldSmSize;

  return (
    <Box>
      <Grid container spacing={1} ref={boxRef}>
        <Grid item xs={12} sm={isWideBlockWidth && !profileMode ? 6 : 12}>
          <Controller
            control={control}
            name={`${name}.line1`}
            render={({ field }) => (
              <TextFieldWithReset
                fullWidth
                variant="outlined"
                autoComplete="off"
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                label={IntlService.formatMessage(inputLabels.addressLine1)}
                isDefaultCanResetFlag={isDefaultCanResetFlag}
                reset={defaultValues?.line1}
                multi={isMultiEdit}
                error={!!formErrors?.line1}
                helperText={renderIntlMessage(formErrors?.line1?.message)}
                recommended={!!recommendedFields?.get(RequiredProfileInfo.Address)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={isWideBlockWidth && !profileMode ? 6 : 12}>
          <Controller
            control={control}
            name={`${name}.line2`}
            render={({ field }) => (
              <TextFieldWithReset
                isDefaultCanResetFlag={isDefaultCanResetFlag}
                value={field.value}
                onChange={field.onChange}
                label={IntlService.formatMessage(inputLabels.addressLine2)}
                variant="outlined"
                autoComplete="off"
                onBlur={field.onBlur}
                fullWidth
                reset={defaultValues?.line2}
                multi={isMultiEdit}
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={zipCodeFieldSmSize}>
          <Controller
            control={control}
            name={`${name}.zipCode`}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextFieldWithReset
                variant="outlined"
                isDefaultCanResetFlag={isDefaultCanResetFlag}
                label={IntlService.formatMessage(inputLabels.zipCode)}
                onBlur={onBlur}
                onChange={newValue => {
                  const zipCodeInfo = zipcodes.lookup(newValue);

                  if (zipCodeInfo) {
                    setValue(nameCity, zipCodeInfo.city);
                    void trigger(nameCity);

                    const stateOption = stateOptions.find(item => {
                      return item.title === zipCodeInfo.state;
                    });

                    if (stateOption) {
                      setValue(nameState, stateOption);
                      void trigger(nameState);
                    }
                  }

                  onChange(newValue);
                }}
                fullWidth
                error={!!formErrors?.zipCode}
                helperText={renderIntlMessage(formErrors?.zipCode?.message)}
                value={value}
                InputProps={{
                  inputComponent: ZipCodeMaskedInput,
                  onBlur,
                }}
                reset={defaultValues?.zipCode}
                multi={isMultiEdit}
                recommended={!!recommendedFields?.get(RequiredProfileInfo.Address)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={cityFieldSmSize}>
          <Controller
            control={control}
            name={nameCity}
            render={({ field }) => (
              <TextFieldWithReset
                variant="outlined"
                isDefaultCanResetFlag={isDefaultCanResetFlag}
                label={IntlService.formatMessage(inputLabels.city)}
                onBlur={field.onBlur}
                onChange={field.onChange}
                fullWidth
                error={!!formErrors?.city}
                helperText={renderIntlMessage(formErrors?.city?.message)}
                value={field.value}
                reset={defaultValues?.city}
                multi={isMultiEdit}
                recommended={!!recommendedFields?.get(RequiredProfileInfo.Address)}
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={stateFieldSmSize}>
          <Controller
            name={nameState}
            control={control}
            render={({ field }) => {
              return (
                <MultipleSelect
                  disableTagsRender
                  disableClearable
                  onChange={field.onChange}
                  label={IntlService.formatMessage(inputLabels.state)}
                  value={field.value}
                  options={stateOptions}
                  fullWidth
                  error={!!formErrors?.state}
                  helperText={renderIntlMessage(formErrors?.state?.message) || ''}
                  resetValue={defaultValues?.state}
                  canReset={isMultiEdit}
                  recommended={!!recommendedFields?.get(RequiredProfileInfo.Address)}
                />
              );
            }}
          />
        </Grid>

        {/* // TODO uncomment after adding countries */}
        {/*  <Grid item sm={6} xs={12}>
          <Controller
            name={`${name}.country`}
            control={control}
            render={({ value, onChange }) => (
              <CountrySelect
                value={value}
                onChange={onChange}
                error={!!errors.address?.country}
                helperText={renderIntlMessage(errors.address?.country?.message)}
                resetValue={defaultValues?.country}
                canReset={isMultiEdit}
              />
            )}
          />
        </Grid> */}

        {children}
      </Grid>
    </Box>
  );
};

AddressBlock.defaultProps = {
  name: 'address',
};

export default AddressBlock;
