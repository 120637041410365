import { List } from 'immutable';

import { ImmutableObject } from 'common/state/interfaces';
import { ICommonPhoneItem, ICommonPhoneItemImt } from './phone';
import { IPaginatedData } from './pagination';
import {
  IPaymentMethodItem,
  IPaymentMethodItemImt,
} from 'modules/pos-settings/interfaces/paymentMethods';
import {
  DurationUnit,
  INamedEntity,
  INamedEntityImt,
  IShortPerson,
} from 'common/interfaces/common';
import {
  IncludedPackageBillingType,
  IssueFrequencyType,
} from 'modules/services/constants/packages';
// eslint-disable-next-line import/no-cycle
import { IBaseInvoicePackage, ICustomDates } from './membership';
import { RedeemType } from 'common/constants/service';
import { IBillingOption } from 'modules/services/interfaces/packages';
import {
  InvoicePaymentSplitStatus,
  PaymentOptionType,
} from 'common/components/InvoiceOperating/constants';
import { PeakModules } from 'common/constants/peakModules';
import { IClub, IClubImt } from 'common/interfaces/clients';
import {
  CancelMembershipData,
  CancelMembershipDataDTO,
  IBilling,
  IPastDue,
  ReactivateMembershipData,
} from 'common/components/PersonProfile/interfaces';
import { PastDueActions } from 'common/components/PersonProfile/constants';
import { IClubInfo } from '../../modules/crm/interfaces/leads';

export interface IMemberData {
  id?: string;
  personId?: number;
  type?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string | Date;
  photoUrl?: string | null;
  imageUrl?: string | null;
  phones?: Array<ICommonPhoneItem>;
}

export interface IMemberDataImt extends ImmutableObject<IMemberData> {
  get<K extends keyof IMemberData>(key: K): IMemberData[K];

  get(key: 'phones'): ICommonPhoneItemImt;
}

export enum InvoiceStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  VOIDED = 'VOIDED',
  CHECK_OUT = 'CHECK_OUT',
  PENDING = 'PENDING',
  DEFERRED = 'DEFERRED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type IPaginatedMembers = IPaginatedData<IMemberData>;

export enum MenuItemTypes {
  Category = 'CATEGORY',
  Item = 'ITEM',
  Service = 'SERVICE',
  Membership = 'MEMBERSHIP',
}

interface IPackageServiceInvoiceItem extends INamedEntity {
  limited: boolean;
  redeemType: RedeemType;
  limitedRedeemNumber: number;
  billingAmount: number;
  billingType: IncludedPackageBillingType;
  redeemDurationUnit?: DurationUnit;
}

export type IServiceImt = ImmutableObject<IPackageServiceInvoiceItem>;

interface IPackageInvoiceItem extends ICustomDates {
  availableToSellByCurrentEmployee: boolean;
  defaultBillingOption: IBillingOption;
  id: string;
  title: string;
  packageServices: IPackageServiceInvoiceItem[];
}

export interface IPackageInvoiceItemImt extends ImmutableObject<IPackageInvoiceItem> {
  get<K extends keyof IPackageInvoiceItem>(key: K): IPackageInvoiceItem[K];

  get(key: 'packageServices'): List<IServiceImt>;
}

export interface ISearchPackagesResult {
  available: IPackageInvoiceItem[];
  comingSoon: IPackageInvoiceItem[];
}

export interface ISearchPackagesRequestPayload {
  module: PeakModules;
  employeeClubId: string;
  isPaymentStep: boolean;
  searchStr: string;
  salesPersonId: number;
}

export interface ISearchGiftCardsPayload {
  module: PeakModules;
  clubIds: string[];
  searchStr: string;
  isCustomer?: boolean;
}

export interface ISearchPackagesResultImt extends ImmutableObject<ISearchPackagesResult> {
  get<K extends keyof ISearchPackagesResult>(key: K): ISearchPackagesResult[K];

  get(key: 'available'): List<IPackageInvoiceItemImt>;

  get(key: 'comingSoon'): List<IPackageInvoiceItemImt>;
}

export interface ISearchGiftCard {
  id: string;
  name: string;
  amount: number;
  invoiceAmountPercentage: number;

  clubs: IClubInfo[];
}

export type ISearchGiftCardImt = ImmutableObject<ISearchGiftCard>;

export interface IBaseProductItem {
  id?: string;
  inventoryClubId: string;
  order: number;
  title: string;
  imageUrl?: string;
  price?: number;
  taxAmount?: number;
  number?: number;
  onHandAmount?: number;
  stockTracking?: boolean;
  inventoryId?: string;
  inventorySnapshotSourceId?: string;
}

export type IBaseProductItemImt = ImmutableObject<IBaseProductItem>;

// TODO Fix interfaces for product and package
export interface IInvoiceProductItem extends IBaseProductItem {
  issueType?: string;
  type?: MenuItemTypes;
  revenueCode?: string;
  taxAmount?: number;
}

export interface ITopUpBalance {
  amount: number;
}

export interface IGiftCard {
  id: string;
  name: string;
  amount: number;
  invoiceAmountPercentage: number;

  clubs: IClubInfo[];
}

export interface ITopUpBalanceImt extends ImmutableObject<ITopUpBalance> {
  get<K extends keyof ITopUpBalance>(key: K): ITopUpBalance[K];
}

export interface IInvoiceProductItemImt extends ImmutableObject<IInvoiceProductItem> {
  get<K extends keyof IInvoiceProductItem>(key: K): IInvoiceProductItem[K];
}

export interface IPackageInventory {
  id: string;
  number: number;
  frequency: IssueFrequencyType;
  inventory: INamedEntity;
}

export enum PaymentsType {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  TAB_ON_A_CORPORATE_ACCOUNT = 'TAB_ON_A_CORPORATE_ACCOUNT',
  MEMBER_REWARDS = 'MEMBER_REWARDS',
  CUSTOM = 'CUSTOM',
  ON_ACCOUNT = 'ON_ACCOUNT',
  CHECKING_SAVINGS = 'CHECKING_SAVINGS',
}

export enum PaymentAccountType {
  CREDIT_CARD = 'CREDIT_CARD',
  ECHECK = 'ECHECK',
}

export enum PaymentCustomMethodType {
  Used = 'USED',
  Blocked = 'BLOCKED',
  New = 'NEW',
}

export interface IPayment {
  id: string;
  name: string;
  type: PaymentsType;
  balance?: number;
}

export type IPaymentImt = ImmutableObject<IPayment>;

export interface IAddPayment {
  paymentMethod: Partial<IPaymentMethodItem>;
  paymentAmount: number;
  invoiceId: string | number;
}

export interface IPaymentResponse {
  paymentAmount: number;
  paymentMethod: IPaymentMethodItem;
}

export interface INewCreditCardData {
  number: string;
  code: string;
  expirationMonth: number;
  expirationYear: number;
}

export type INewCreditCardDataImt = ImmutableObject<INewCreditCardData>;

export interface IInvoicePaymentSplit {
  id?: string;
  status?: InvoicePaymentSplitStatus; // backend don't require this field on POST/PUT request, it only appears in responses
  invoicePaymentId?: string; // this field required when adding new invoice payment split
  type: PaymentsType;
  paymentAmount: string | number;
  rewardPoints?: string | number;
  // cash
  tenderedAmount?: number;
  changeAmount?: number;
  onAccount?: number;
  // cc
  creditCardPaymentOptionType?: PaymentOptionType;
  // creditCard?:
  paymentAccountId?: string;
  // gift
  paymentMethodId?: string;
  paymentMethodInstanceId?: string;
  giftCardBarcode?: string;
  title?: string;
  applyToAccount?: boolean;
  rewardAmount?: string | number;
}

export interface IInvoicePaymentMethodItemImt extends ImmutableObject<IInvoicePaymentSplit> {
  get<K extends keyof IInvoicePaymentSplit>(key: K): IInvoicePaymentSplit[K];

  get(key: 'creditCard'): INewCreditCardDataImt;
}

export interface IPaymentResponseImt extends ImmutableObject<IPaymentResponse> {
  get<K extends keyof IPaymentResponse>(key: K): IPaymentResponse[K];

  get(key: 'paymentMethod'): IPaymentImt;
}

export interface IPaymentMethodCustomResponse {
  id: string;
  status: PaymentCustomMethodType;
  name: string;
  barcode: string;
  amount: number;
  leftAmount: number;
  paymentMethod: IPaymentMethodItem;
}

export interface IPaymentMethodCustomResponseImt
  extends ImmutableObject<IPaymentMethodCustomResponse> {
  get<K extends keyof IPaymentMethodCustomResponse>(key: K): IPaymentMethodCustomResponse[K];

  get(key: 'paymentMethod'): IPaymentMethodItemImt;
}

export type IShortPersonInfoOfInvoice = Omit<IShortPerson, 'active'> & {
  posDiscountPercentage: number;
};

export interface IUpdatePersonInfoOfInvoiceSuccessPayload {
  invoiceId: string;
  person: IShortPerson;
}

export interface ISocketRemoveMessage {
  registerId: string;
  invoiceId: string;
}

export interface IInvoiceUnitDTO {
  id: string;
  topUpBalance: ITopUpBalance;
  inventory: IInvoiceProductItem;
  bundle: IBaseInvoicePackage;
  billingSchedule: IBilling;
  pastDue: IPastDue;
  giftCard: IGiftCard;
  reactivatePastDues: Array<IInvoiceUnitDTO>;
  cancelPastDues: Array<IInvoiceUnitDTO>;
  pastDueResolve: PastDueActions;
  cancelBillingSchedules: Array<IInvoiceUnitDTO>;
  type: CartUnitTypes;
}

export interface IInvoiceUnitDTOImt extends ImmutableObject<IInvoiceUnitDTO> {
  get<K extends keyof IInvoiceUnitDTO>(key: K): IInvoiceUnitDTO[K];
}

export interface IInvoiceDetailsDto {
  id: string;
  number: number;
  status: InvoiceStatus;

  subtotalAmount: number;
  taxAmount: number;
  totalAmount: number;
  discount: number | null;

  club: IClub;
  register: INamedEntity;

  customer: IShortPersonInfoOfInvoice;
  salesperson: IShortPersonInfoOfInvoice;

  createdDate: string;
  invoicePaymentSplits: Array<IInvoicePaymentSplit>;
  lastModifiedDateLong: number;
  invoiceUnits: Array<IInvoiceUnitDTO>;
}

export interface IInvoiceDetailsImt extends ImmutableObject<IInvoiceDetailsDto> {
  get<K extends keyof IInvoiceDetailsDto>(key: K): IInvoiceDetailsDto[K];

  get(key: 'club'): IClubImt;

  get(key: 'register'): INamedEntityImt;

  get(key: 'customer'): INamedEntityImt;

  get(key: 'salesperson'): INamedEntityImt;

  get(key: 'invoicePaymentSplits'): List<IInvoicePaymentMethodItemImt>;

  get(key: 'invoiceUnits'): List<IInvoiceUnitDTOImt>;
}

export interface IInvoiceUpdateData
  extends Omit<
    IInvoiceDetailsDto,
    | 'customer'
    | 'salesperson'
    | 'payments'
    | 'topUpBalances'
    | 'register'
    | 'createdDate'
    | 'cancelBundleDto'
  > {
  customerId: number;
  topUpBalances: Omit<ITopUpBalance, 'id'>;
  salespersonId: string;
  payment?: IInvoicePaymentSplit;
  cancelBundleDTO: CancelMembershipDataDTO;
}

export interface ICreateNewInvoiceData {
  id: string;
  number: number;
}

export type ICreateNewInvoiceDataImt = ImmutableObject<ICreateNewInvoiceData>;

export interface INotifications {
  print: boolean;
  sendByEmail: boolean;
  sendByText: boolean;
  sendNotification: boolean;
}

export interface ICreateInvoiceRequestPayload {
  module: PeakModules;
  registerId: string;
  shouldFocus?: boolean; // should this invoice become selected
  customerId?: number;
  isCustomer?: boolean;
  callback?: () => void;
  // need to fetch profile payments history
  profileId?: number;
}

export interface IBalance {
  balance: number;
}

export interface IRewards {
  points: number;
}

export type IBalanceImt = ImmutableObject<IBalance>;
export type IRewardsImt = ImmutableObject<IRewards>;

export interface IVoidInvoiceRequestPayload {
  module?: PeakModules;
  invoiceId: string;
  reason: string;
  isMembershipStep?: boolean;
  shouldGoPrevStep?: boolean;
  profileId?: number;
  isRecentPersonPaymentsUpdate?: boolean;
}

export enum CartUnitTypes {
  SERVICE_BUNDLE = 'SERVICE_BUNDLE',
  MEMBERSHIP_BUNDLE = 'MEMBERSHIP_BUNDLE',
  TOP_UP_BALANCE = 'TOP_UP_BALANCE',
  INVENTORY = 'INVENTORY',
  GIFT_CARD = 'GIFT_CARD',
  PAST_DUE = 'PAST_DUE',
  BILLING_SCHEDULE = 'BILLING_SCHEDULE',
  CANCEL_MEMBERSHIP = 'CANCEL_MEMBERSHIP',
  CANCEL_NON_MEMBERSHIP = 'CANCEL_NON_MEMBERSHIP',
  REACTIVATE_MEMBERSHIP = 'REACTIVATE_MEMBERSHIP',
  REACTIVATE_NON_MEMBERSHIP = 'REACTIVATE_NON_MEMBERSHIP',
}

export interface IAddInvoiceUnitDTO {
  createNewInvoice: boolean;
  registerId: string;
  billingSchedules: string[];
  pastDues: string[];
  giftCards: string[];
  cancelBundleDto?: CancelMembershipData;
  reactivateBundleDto?: ReactivateMembershipData;
  inventoryClubs: Array<IInvoiceProductItem>;
  memberId: number;
  membershipPackageInstanceId: string;
  servicePackages: any[];
  topUpBalances: ITopUpBalance[];
}

export interface IRevenueCodeWithCodeDto {
  code: string;
  id: string;
  title: string;
}

export interface IRevenueCodeWithCodeDtoImt extends ImmutableObject<IRevenueCodeWithCodeDto> {
  get<K extends keyof IRevenueCodeWithCodeDto>(key: K): IRevenueCodeWithCodeDto[K];
}

export interface IFetchReceiptPayload {
  module?: PeakModules;
  invoiceId: string;
  isCustomer?: boolean;
  isSilentPrint?: boolean;
}
